var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{ remMargin:_vm.$store.state.isMobile },attrs:{"id":"rumah"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.data[`Banner Image ${_vm.tipe}`][0].thumbnails.full.url),expression:"data[`Banner Image ${tipe}`][0].thumbnails.full.url"}],attrs:{"data-srcset":` ${_vm.data[`Banner Image ${_vm.tipe}`][0].thumbnails.full.url} 1900w,
                    ${_vm.data[`Banner Image ${_vm.tipe}`][0].thumbnails.large.url} 700w`,"alt":""}}),_c('div',{staticClass:"info"},[_c('div',{staticClass:"leftInfo"},[_c('h1',[_vm._v(_vm._s(_vm.data.Name))]),(_vm.data[`Deskripsi ${_vm.tipe}`])?_c('p',{staticClass:"desc"},[_vm._v(_vm._s(_vm.data[`Deskripsi ${_vm.tipe}`]))]):_vm._e(),_c('span',[_vm._m(0),_c('p',[_vm._v(_vm._s(_vm.data.Lokasi))])])]),_c('div',{staticClass:"rightInfo"},[_vm._m(1),_c('h2',[_vm._v("Rp. "+_vm._s(_vm.rupiah))]),_vm._m(2),_c('p',[_vm._v(_vm._s(_vm.data[`Status ${_vm.tipe}`]))])])]),_c('div',{staticClass:"details"},[_c('h2',[_vm._v("Spesifikasi")]),_c('ul',[(_vm.tipe === 'Rumah')?_c('li',[_c('i',{staticClass:"las la-user-friends"}),_c('p',[_vm._v(_vm._s(_vm.data.Kamar)+" Kamar Tidur")])]):_vm._e(),_c('li',[_c('i',{staticClass:"las la-expand-arrows-alt"}),_c('p',[_vm._v("Luas Tanah "+_vm._s(_vm.data[`Luas Tanah ${_vm.tipe}`])+"m"),_c('sup',[_vm._v("2")])])]),_c('li',[_c('i',{staticClass:"las la-home"}),_c('p',[_vm._v("Luas Bangunan "+_vm._s(_vm.data[`Luas Bangunan ${_vm.tipe}`])+"m"),_c('sup',[_vm._v("2")])])]),(_vm.data.Taman)?_c('li',[_c('i',{staticClass:"las la-check"}),_c('p',[_vm._v("Taman")])]):_vm._e(),(_vm.data['Tempat Parkir'])?_c('li',[_c('i',{staticClass:"las la-check"}),_c('p',[_vm._v("Tempat Parkir")])]):_vm._e(),(_vm.data['Keamanan 24 Jam'])?_c('li',[_c('i',{staticClass:"las la-shield-alt"}),_c('p',[_vm._v("Keamanan 24 Jam")])]):_vm._e()]),_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.data[`Denah ${_vm.tipe}`][0].thumbnails.full.url),expression:"data[`Denah ${tipe}`][0].thumbnails.full.url"}],attrs:{"data-srcset":` ${_vm.data[`Denah ${_vm.tipe}`][0].thumbnails.full.url} 1900w,
                      ${_vm.data[`Denah ${_vm.tipe}`][0].thumbnails.large.url} 700w`,"alt":""}})]),(_vm.data[`Gallery Image ${_vm.tipe}`])?_c('div',{staticClass:"gallery"},_vm._l((_vm.data[`Gallery Image ${_vm.tipe}`]),function(img,i){return _c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(img.thumbnails.full.url),expression:"img.thumbnails.full.url"}],key:i,attrs:{"data-srcset":` ${img.thumbnails.full.url} 1900w,
                      ${img.thumbnails.large.url} 700w`,"alt":""}})}),0):_vm._e(),_c('GmapMap',{ref:"mapRef",staticClass:"map",attrs:{"center":_vm.pos,"zoom":15,"map-type-id":"terrain","options":{
      styles: _vm.style,
      disableDefaultUI: true,
      gestureHandling: 'cooperative',
    }}},[_c('gmap-info-window',{attrs:{"options":_vm.infoOptions,"position":_vm.pos,"opened":true}}),_c('GmapMarker',{attrs:{"position":_vm.pos,"animation":2,"clickable":false,"draggable":false,"icon":{
        url: require('../assets/marker.svg'),
        size: {width: 78, height: 95, f: 'px', b: 'px',},
        scaledSize: {width: 39, height: 48, f: 'px', b: 'px',},
        anchor: { x: 19.5, y: 48, f: 'px', b: 'px'},
      }}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"las la-map-marker"}),_c('b',[_vm._v("Lokasi")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('i',{staticClass:"las la-money-check"}),_c('b',[_vm._v("Harga Mulai Dari")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"margin"},[_c('i',{staticClass:"las la-hammer"}),_c('b',[_vm._v("Status Konstruksi")])])
}]

export { render, staticRenderFns }